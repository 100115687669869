import React from 'react';
import SEO from '../../components/common/seo';
import Layout from '../../components/common/layout';
import Banner from '../../components/common/hero';
import AboutUsSvg from '../../assets/svgs/about-us.svg';

const render = <AboutUsSvg />;

const IndexPage = () => {
  return (
    <>
      <SEO title="About us" description="A nice description about us." />
      <Layout>
        <Banner
          heading="Data drives results"
          description="We're a digital agency obessed with about data and we know you are too."
          ctaLink={`#`}
          ctaText="Sign up to our newsletter"
        >
          {render}
        </Banner>
      </Layout>
    </>
  );
};

export default IndexPage;
